<template>
  <v-container fluid>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-text-field
          hide-details
          prepend-icon="fas fa-search"
          single-line
          label="Buscar..."
          @input="indexData()"
          v-model="query"
        ></v-text-field>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ dataTable.length }} Cliente(s)
        </v-chip>
        <v-chip
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'clientes/formulario' }"
        >
          Novo
        </v-chip>
      </v-toolbar>
    </div>

    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="dataLoading"
      ></v-progress-circular>
    </div>
    <Item v-for="item in dataTable" :key="item.id" :item="item" :list="headers">
      <template v-slot:acoes>
        <strong>
          Responsável
          <v-chip color="primary" @click="editarResponsaveis(item.id)" small
            >editar</v-chip
          >
        </strong>
        <p v-if="responsaveis">{{ getResponsavel(item.id) }}</p>
        <v-divider></v-divider>
        <strong>
          Co-responsáveis
          <v-chip color="primary" @click="editarCoresponsaveis(item.id)" small
            >editar</v-chip
          >
        </strong>
        <ol v-if="coresponsaveis">
          <li
            v-for="coresponsavel in getCoresponsaveis(item.id)"
            :key="'coresponsavel' + coresponsavel.id"
          >
            {{ coresponsavel.name }}
          </li>
        </ol>
        <p v-if="getCoresponsaveis(item.id).length == 0">Nenhum</p>
      </template>
    </Item>
    <div class="text-center">
      <v-dialog v-model="showDialogResponsavel" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">
            Atualizar resposável
          </v-card-title>

          <v-card-text>
            <v-select
              :items="users"
              v-model="dialogResponsavel.responsavel_id"
              item-text="name"
              item-value="id"
              label="Responsaveis"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="showDialogResponsavel = false"> Fechar </v-btn>
            <v-btn color="green" @click="salvarResponsavel()"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDialogCoresponsavel" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">
            Atualizar Co-resposável
          </v-card-title>

          <v-card-text>
            <v-select
              multiple
              :items="users"
              v-model="dialogCoresponsavel.responsavel_id"
              item-text="name"
              item-value="id"
              label="Co-responsaveis"
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="showDialogCoresponsavel = false"> Fechar </v-btn>
            <v-btn color="green" @click="salvarCoresponsavel()"> Confirmar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import ToastService from "@/services/ToastService";
import ClienteService from "@/services/Cliente/ClienteService";
import UserService from "@/services/User/UserService";
import AuthorizationService from "@/services/AuthorizationService";
import Item from "@/components/CardListItem/Item";

export default {
  name: "ClienteIndex",

  created: async function() {
    await this.indexData();
    await this.getUsers();
    await this.getResponsaveis();
    await this.getCoresponsaveisList();
  },

  components: {
    Item,
  },

  data: () => ({
    dialogResponsavel: {},
    dialogCoresponsavel: {},
    selected: [],
    users: [],
    query: "",
    dataTable: [],
    responsaveis: undefined,
    coresponsaveis: undefined,
    loading: true,
    dialog: false,
    confirmData: {},
    showDeleteModal: false,
    showDialogResponsavel: false,
    showDialogCoresponsavel: false,
    dataLoading: true,
    dataToModal: {},
    paginateParams: {},
    AuthorizationService: AuthorizationService,
    ClienteService: new ClienteService(""),
    UserService: new UserService(""),
    headers: [
      { title: "NOME_CLIENTE", nick: "Nome" },
      { title: "CNPJ", nick: "CNPJ" },
    ],
  }),

  methods: {
    async indexData(queryString = "") {
      console.log(queryString);
      try {
        this.dataLoading = true;

        const res = await this.ClienteService.index(
          "cliente?search=" + this.query
        );

        this.dataTable = res;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.dataLoading = false;
      }
    },

    async getUsers() {
      try {
        const res = await this.UserService.index("user?search=" + this.query);

        this.users = res;
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    async getResponsaveis() {
      await window.db.collection("responsaveis").onSnapshot((query) => {
        let responsaveis = [];

        query.forEach((obj) => {
          const data = obj.data();
          responsaveis.push({
            doc: obj.id,
            ...data,
          });
        });

        this.responsaveis = responsaveis;
      });
    },

    getIdResponsavel(idCliente) {
      if (this.responsaveis) {
        let responsavel = this.responsaveis.find(
          (element) => element.cliente_id == idCliente
        );
        if (responsavel) {
          let user = this.users.find(
            (element) => element.id == responsavel.responsavel_id
          );

          return user.id;
        } else {
          return "Nenhum";
        }
      }
    },

    getIdCoresponsavel(idCliente) {
      if (this.coresponsaveis) {
        let coresponsaveis = this.coresponsaveis.filter(
          (element) => element.cliente_id == idCliente
        );

        
       let ids = [];
       
       coresponsaveis.forEach(element => {
         ids.push(parseInt(element.responsavel_id))
       })

       return ids;

      } else {
        console.log('erro')
      }
    },

    editarResponsaveis(id) {
      this.dialogResponsavel = {};
      this.showDialogResponsavel = true;
      this.dialogResponsavel.responsavel_id = this.getIdResponsavel(id);
      this.dialogResponsavel.cliente_id = id;
    },

    editarCoresponsaveis(id) {
      console.log(id);
      this.dialogCoresponsavel = {};
      this.showDialogCoresponsavel = true;
      this.dialogCoresponsavel.responsavel_id = this.getIdCoresponsavel(id);
      this.dialogCoresponsavel.cliente_id = id;
    },

    salvarResponsavel() {
      window.db
          .collection("eventos")
          .doc(this.responsaveis.find(element => element.cliente_id == this.dialogResponsavel.cliente_id).doc)
          .update({ responsavel_id: this.dialogResponsavel.responsavel_id })
          .then((res) => {
            console.log(res);
            ToastService("Evento atualizado.", "success");
          });
    },

    salvarCoresponsavel() {

    },

    getResponsavel(id) {
      if (this.responsaveis) {
        let responsavel = this.responsaveis.find(
          (element) => element.cliente_id == id
        );
        if (responsavel) {
          let user = this.users.find(
            (element) => element.id == responsavel.responsavel_id
          );

          return user.name;
        } else {
          return "Nenhum";
        }
      }
    },

    getCoresponsaveis(id) {
      if (this.coresponsaveis) {
        let coresponsaveis = this.coresponsaveis.filter(
          (element) => element.cliente_id == id
        );

        return this.users.filter((element) => {
          return coresponsaveis.find((el) => el.responsavel_id == element.id);
        });
      }
    },

    async getCoresponsaveisList() {
      await window.db.collection("coresponsaveis").onSnapshot((query) => {
        let coresponsaveis = [];

        query.forEach((obj) => {
          const data = obj.data();

          coresponsaveis.push({
            doc: obj.id,
            ...data,
          });
        });

        this.coresponsaveis = coresponsaveis;
      });
    },

    async deleteData() {
      try {
        await this.ClienteService.destroy("cliente/" + this.dataToModal.id);

        ToastService("Ação realizada com sucesso.", "success");

        await this.indexData();
      } catch (exception) {
        ToastService("Erro ao realizar operação.", "error");
      } finally {
        this.loading = false;
      }
    },

    selectFile(item) {
      this.dataToModal = item;

      this.dialog = true;
    },

    destroy() {
      this.ClienteService.destroy("cliente/" + this.dataToModal.id);
    },

    verifyDeleteData(item) {
      this.confirmData = item;
      this.showDeleteModal = true;
    },

    async confirmDeleteData(item) {
      this.loading = true;

      this.cancelDeleteData();

      await this.deleteData(item.id);
    },

    cancelDeleteData() {
      this.confirmData = {};
      this.dialog = true;
    },

    redirectForm(path) {
      this.$router.push({ path: path });
    },

    async setPage(page) {
      await this.indexData(`?page=${page}`);
    },
  },
};
</script>
